// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'HomeIndex',
    component: () => import('@/views/home/index')
  },
  {
    path: '/about',
    name: 'AboutIndex',
    component: () => import('@/views/about/index')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
