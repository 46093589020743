<template>
  <div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
    >
      <!--      <el-menu-item index="1">-->
      <!--        <router-link to="/">Home</router-link>-->
      <!--      </el-menu-item>-->
      <el-menu-item index="1">
        <router-link to="/">名望计算器</router-link>
      </el-menu-item>
      <el-menu-item index="2">
        <router-link to="/about">攻速鞋计算器（已淘汰）</router-link>
      </el-menu-item>
      <!-- Add more menu items as needed -->
    </el-menu>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

// Route-to-Index Mapping
const routeToIndex = {
  '/': '1',
  '/about': '2',
  '/services': '3'
  // Add more route-to-index mappings as needed
}

// Computed property for activeIndex
const activeIndex = computed(() => routeToIndex[route.path] || '1')

// Watch for route changes to update activeIndex
watch(() => route.path, (newPath) => {
  console.log(newPath)
  // No need to manually update activeIndex, as it's computed
})
</script>

<style>
.el-menu-demo {
  background-color: #333;
  color: #fff;
}
.el-menu-demo .el-menu-item {
  color: #fff;
}
.el-menu-demo .el-menu-item:hover {
  background-color: #555;
}
</style>
